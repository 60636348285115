import React from "react"
import * as styles from './style.module.sass'

import NewsItemText from '../../atoms/NewsItemText'
import NewsPostUnderbarLinkButton from '../../atoms/NewsPostUnderbarLinkButton'

const NewsPostSidebar = (props) => {
  return(
    <div className={styles.newsPostSidebar}>
      <section className={styles.newsPostSidebarMain}>
        <NewsItemText
          layoutName={props.layoutName}
          title={props.newsItem.title}
          date={props.newsItem.date}
        />
      </section>
      <div className={styles.newsPostSidebarButton}>
        <NewsPostUnderbarLinkButton
          layoutName={props.layoutName}
          linkTo={'/news/'}
          text={"Index"}
        />
      </div>
    </div>
  )
}

export default NewsPostSidebar
