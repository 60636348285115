import React from 'react'
import HTMLReactParser from 'html-react-parser'
import * as styles from './style.module.sass'

import HoverProvider from '../HoverProvider'

const NewsPostText = (props) => {

  const options = {
    replace: (node) => {
      if (
        node.name === 'a' &&
        node.attribs &&
        node.attribs.href
      ) {
        return (
          <HoverProvider
            hoverClassName={styles.NewsPostTextBodyLinkIsHover}
          >
            <a
              href={node.attribs.href}
              target={node.attribs.target}
              rel={node.attribs.rel}
              className={styles.NewsPostTextBodyLink}
            >
              {node.children[0].data}
            </a>
          </HoverProvider>
        )
      }
    }
  }


  return (
    <div
      className={styles.NewsPostTextBody}
    >
      {HTMLReactParser(props.text, options)}
    </div>
  )
}

export default NewsPostText
