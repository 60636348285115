import React from "react"
import * as styles from './style.module.sass'

import NewsPostUnderbarLinkButton from "../../atoms/NewsPostUnderbarLinkButton";

const NewsPostLinks = (props) => {
  return(
    <ul className={styles.NewsPostLinksList}>
      <li className={styles.NewsPostLinksItem}>
        <NewsPostUnderbarLinkButton
          layoutName={props.layoutName}
          linkTo={props.nextPost!=null? `/news/${props.nextPost.slug}/` : ''}
          text={"Prev"}
        />
      </li>
      <li className={styles.NewsPostLinksItem}>
        <NewsPostUnderbarLinkButton
          layoutName={props.layoutName}
          linkTo={props.previousPost!=null? `/news/${props.previousPost.slug}/`: ''}
          text={"Next"}
        />
      </li>
    </ul>
  )
};

export default NewsPostLinks
