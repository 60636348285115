import React, {useRef} from "react"
import * as styles from './style.module.sass'

import NewsPostSidebar from "../../organisms/NewsPostSidebar"
import NewsPostBody from "../../organisms/NewsPostBody"
import Footer from "../../organisms/Footer"

const NewsPostTemplate = (props) => {
  const layoutName = useRef('/news-post/');


  return (
    <div
      className={styles.container}
      id={"container"}
    >
      <div
        className={styles.containerWrap}
      >
        <article className={styles.newsPost}>
          <div className={styles.newsPostSidebar}>
            <NewsPostSidebar
              layoutName={layoutName.current}
              newsItem={props.newsPost}
            />
          </div>
          <div className={styles.newsPostBody}>
            <div className={styles.newsPostBodyWrap}>
              <NewsPostBody
                layoutName={layoutName.current}
                newsPost={props.newsPost}
                nextPost={props.nextPost}
                previousPost={props.previousPost}
              />
            </div>
          </div>
        </article>
        <Footer
          layoutName={layoutName.current}
        />
      </div>
    </div>
  )
}

export default NewsPostTemplate
