import React from "react"
import * as styles from './style.module.sass'

import SingleImage from "../../atoms/SingleImage"
import PlaceholderImage from "../../atoms/PlaceholderImage"
import NewsPostText from "../../atoms/NewsPostText"
import NewsPostLinks from "../../molecules/NewsPostLinks"

const NewsPostBody = (props) => {
  return(
    <div className={styles.NewsPostBody}>
      <div className={styles.NewsPostBodyMain}>
        {props.newsPost.newsContent.newsContentImage && (
          <div className={styles.NewsPostBodyMainImage}>
            <SingleImage
                layoutName={props.layoutName}
                source={props.newsPost.newsContent.newsContentImage.localFile}
                alt={props.newsPost.title}
                sizes={"(max-width: 375px) 80vw, 1920px"}
            />
          </div>
        )
        // :(
        //   <div className={styles.NewsPostBodyMainPlaceholder}>
        //     <PlaceholderImage
        //       width={'35%'}
        //       absolute={true}
        //     />
        //   </div>
        // )
        }
        <NewsPostText
          layoutName={props.layoutName}
          text={props.newsPost.newsContent.newsContentText}
        />
      </div>
      <NewsPostLinks
        layoutName={props.layoutName}
        previousPost={props.previousPost}
        nextPost={props.nextPost}
      />
    </div>
  )
}

export default NewsPostBody
