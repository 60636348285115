import React from "react"
import { graphql } from "gatsby"

import Seo from "../components/organisms/seo"
import NewsPostTemplate from "../components/template/NewsPostTemplate"

const Post = ({ data }) => {
  return (
    <>
      <Seo title={data.post.title} />
      <NewsPostTemplate
        newsPost={data.post}
        nextPost={data.next}
        previousPost={data.previous}
      />
    </>
  )
}

export default Post

export const query = graphql`
  query(
    $id: String,
    $nextPostId: String,
    $previousPostId: String
  ) {
    post: wpNews(id: {eq: $id}) {
      title
      date(formatString: "YY.MM.DD")
      newsContent {
        newsContentText
        newsContentImage {
          localFile {
            childImageSharp {
              gatsbyImageData(
                formats: [AUTO, WEBP]
                breakpoints: [750, 1920]
                quality: 100
                width: 1920
              )
            }
          }
        }
      }
    }
    previous: wpNews(id: {eq: $previousPostId}) {
      slug
    }
    next: wpNews(id: {eq: $nextPostId}) {
      slug
    }
  }
`