import React from 'react'
import { Link } from "gatsby"
import * as styles from './style.module.sass'

import HoverProvider from '../HoverProvider'

const NewsPostUnderbarLinkButton = (props) => {
  if(props.linkTo!='') {
    return(
      <HoverProvider hoverClassName={styles.isHover}>
        <Link
          to={props.linkTo}
          className={styles.NewsPostUnderbarLinkButton}
        >{props.text}</Link>
      </HoverProvider>
    )
  }else{
    return(
      <p className={styles.NewsPostUnderbarLinkButton + " " + styles.NewsPostUnderbarLinkButtonBlank}>
        {props.text}
      </p>
    )
  }
}

export default NewsPostUnderbarLinkButton
